
import Vue from "vue";
import Preloader from "@/components/dialogs/Preloader.vue";
import Alert from "@/components/dialogs/Alert.vue";

export default Vue.extend({
  name: "Auth",

  components: { Alert, Preloader }
});
